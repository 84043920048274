import React from 'react';

import { AccountProvider } from '../contexts/Account';
import { OrderProvider } from '../contexts/Order';

export default function Providers({ children }) {
  return <AccountProvider>
    <OrderProvider>
      {children}
    </OrderProvider>
  </AccountProvider>;
}
