import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAccountContext } from '../contexts/Account';

const AuthenticatedRoutes = () => {
  const { isAuthenticated } = useAccountContext();

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default AuthenticatedRoutes;
