import Request from '../system/Request';
import Device from './Device';
import Workspace from './Workspace';
import License from './License';

const Account = {
  login: (data, isTwoStep) => {
    const body = {
      _username: data.username,
      _password: data.password
    };

    if (isTwoStep) {
      body._two_step = Object.keys(data.two_step).map((k) => data.two_step[k]).join('');
    }

    return new Promise((resolve, reject) => {
      (new Request()).post('/api/login_check', JSON.stringify(body), {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  register: (data) => {
    const body = {
      forename: data['first_name'],
      surname: data['last_name'],
      company: null,
      email: data['email'],
      password: data['password']
    };

    return new Promise((resolve, reject) => {
      (new Request()).post('/api/users', JSON.stringify(body), {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  recover: (data) => {
    return new Promise((resolve, reject) => {
      (new Request()).post('/api/users/recover', JSON.stringify({
        email: data['email'],
        password: btoa(data['new_password'])
      }), {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, false).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  },

  get: () => {
    return new Promise((resolve, reject) => {
      (new Request()).get('/api/users/me', {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  search: (email) => {
    return new Promise((resolve, reject) => {
      if (0 === email.length) {
        reject();
        return;
      }

      (new Request()).get('/api/users/search/' + email, {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  updateAccount: (data) => {
    return new Promise((resolve, reject) => {
      (new Request()).put('/api/users/me/update', JSON.stringify(data), {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  changePassword: (data) => {
    return new Promise((resolve, reject) => {
      (new Request()).post('/api/users/me/password', JSON.stringify(data), {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  updateKeys: (publicKey, privateKey) => {
    return new Promise((resolve, reject) => {
      (new Request()).post('/api/users/me/keys', JSON.stringify({
        public: btoa(JSON.stringify(publicKey)),
        private: btoa(JSON.stringify(privateKey))
      }, true), {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  Device: Device,
  Workspace: Workspace,
  License: License
};

export default Account;
